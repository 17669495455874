.contFeedback, .contMap {
    margin-top: 20px;
    display: flex;
    justify-content: center;
}

.card-body {
    padding-left: 0px;
    padding-right: 0px;
}

